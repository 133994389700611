import { from, Observable } from 'rxjs';
import { lastValueFrom } from 'rxjs';
import { Credentials, ICredentials } from '../interfaces/credentials';
import { StorageService } from './storage.service';
import { getCurrentLocale } from './i18n.service';
import { ShopConfigService } from './shop-config.service';
import { IError } from '../interfaces/error';
import { IAuthResponse } from '../interfaces/auth-response';

export type RestCache = 'reload' | 'no-store' | 'no-cache' | 'force-cache' | 'default';

export abstract class RestService {
  private refreshPromise: Promise<unknown> | null = null;
  private headers: Headers = new Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });
  private api: string;

  protected constructor() {}

  public setApi(api: string) {
    this.api = api;
  }

  public setAuthHeader(credentials: ICredentials) {
    const shopId = ShopConfigService.getShopId();
    if (credentials && (credentials.accessToken || credentials.jwt)) {
      this.setHeader('Authorization', 'Bearer ' + credentials.accessToken);
      this.setHeader('locale', getCurrentLocale());
    } else {
      this.deleteHeader('Authorization');
    }
    if (shopId) {
      this.setHeader('x-shop-id', shopId); // Dynamically updated shopId
    }
  }

  public appendHeader(name: string, value: string) {
    this.headers.append(name, value);
  }

  public setHeader(name: string, value: string) {
    this.headers.set(name, value);
  }

  public deleteHeader(name: string) {
    this.headers.delete(name);
  }

  public read(url: string, data?: Object, cache: RestCache = 'default'): Observable<any> {
    return this.fetch(this.request(url, 'GET', data, cache));
  }

  public create(url: string, data?: Object): Observable<any> {
    return this.fetch(this.request(url, 'POST', data));
  }

  public update(url: string, data?: Object): Observable<any> {
    return this.fetch(this.request(url, 'PUT', data));
  }

  public add(url: string, data?: Object): Observable<any> {
    return this.fetch(this.request(url, 'PATCH', data));
  }

  public delete(url: string): Observable<any> {
    return this.fetch(this.request(url, 'DELETE', null));
  }

  private request(
    endPoint: string,
    method: string,
    data?: Object,
    cache: RestCache = 'default',
    requestCredentials: RequestCredentials = 'omit'
  ): Request {
    method = method.toUpperCase();

    if (!this.api) {
      throw new Error('No API is set');
    }

    const credentials = new Credentials().deserialize(StorageService.get('credentials'));
    this.setAuthHeader(credentials);
    let api = this.api + endPoint;
    const init: RequestInit = {
      method,
      headers: this.headers,
      cache,
      credentials: requestCredentials,
      body: null,
    };

    if (data) {
      if (method === 'GET') {
        // Use URLSearchParams to create a query string
        const queryString = new URLSearchParams(data as any).toString();
        api += '?' + queryString;
      } else {
        init.body = JSON.stringify(data);
      }
    }
    return new Request(api, init);
  }

  async getRefreshToken(): Promise<IAuthResponse> {
    const credentials = new Credentials().deserialize(StorageService.get('credentials'));
    const response: IAuthResponse = await lastValueFrom(
      this.fetch(this.request('/auth/refresh-token', 'POST', {
        refreshToken: credentials.refreshToken,
      }))
    ) as IAuthResponse;
    this.setCredentials(response);
    return response;
  }

  protected setCredentials(auth: IAuthResponse) {
    const credentials: ICredentials = new Credentials().deserialize(auth);
    if (auth?.coupon) {
      StorageService.set('coupon', auth.coupon);
    }
    this.setAuthHeader(credentials);
    StorageService.set('credentials', credentials);
  }

  private async waitForTokenRefresh(): Promise<void> {
    if (!this.refreshPromise) {
      this.refreshPromise = this.getRefreshToken();
      try {
        await this.refreshPromise;
      } finally {
        this.refreshPromise = null;
      }
    } else {
      await this.refreshPromise;
    }
  }

  /**
   * Helper that performs the fetch and handles token refresh.
   */
  private async fetchAsync(request: Request): Promise<Object | IError | IAuthResponse | string | undefined> {
      const response = await fetch(request);
      const text = await response.text();
      let data: any;
      try {
        data = text ? JSON.parse(text) : null;
      } catch (err) {
        data = text;
      }
      if (response.ok) {
        return data;
      } else if (['invalidSignature', 'tokenHasExpired'].includes((data as IError)?.systemCode)) {
        await this.waitForTokenRefresh();
        // Retry the request after refreshing token
        return this.fetchAsync(new Request(request, { headers: this.headers }));
      }else {
        throw data;
      }
  }

  private fetch(request: Request): Observable<Object | IAuthResponse | IError | string | undefined> {
    return from(this.fetchAsync(request));
  }
}
